import classNames from 'classnames';
import Img from 'gatsby-image';
import React from 'react';
import Column from './Column';
import Grid from './Grid';

const TeamTeaser = ({
	image,
	title,
	imageRight = false,
	subtitle,
	meta,
	description,
}) => {
	const Image = image ? (
		<Column md="1/3">
			<Img className="" fluid={image} />
		</Column>
	) : null;

	return (
		<Grid className="mt-8">
			{!imageRight && Image}
			<Column
				md="2/3"
				className={classNames(
					{
						'text-right': imageRight,
					},
					['mt-2', 'md:mt-0'],
				)}
			>
				<div className="text-md font-bold mb-2">{title}</div>
				{subtitle && (
					<div className="mb-2 text-sm italic">{subtitle}</div>
				)}
				{description && (
					<div>
						<div className="mb-2">{description}</div>
					</div>
				)}
				{meta && <div className="font-bold">{meta}</div>}
			</Column>
			{imageRight && Image}
		</Grid>
	);
};

export default TeamTeaser;
