import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout';
import TeamTeaser from '../../components/TeamTeaser';

const findImage = (name, data) => {
	const d = data.find((d) => d.node.name === name);

	return d ? d.node.childImageSharp.fluid : null;
};

const PraxisTeam = ({ data, location, ...props }) => {
	return (
		<Layout location={location} title="Praxis-Team" noSlider {...props}>
			<TeamTeaser
				image={findImage('dr_krokowski', data.teaserImages.edges)}
				title="Dr. med. Michael H. Krokowski"
				subtitle="Nachfolger von Herrn Dr. K. Vivell"
				meta="Seit 1995 niedergelassen"
				description="Facharzt für Dermatologie Zusatzbezeichnungen/Schwerpunkte:
                    Allergologie, Phlebologie, Umweltmedizin, Dermatochirurgie,
                    Ästhetische Lasertherapie, Dermatologische Kosmetologie,
                    Ästhetische Dermatologie, Proktologie, Berufsdermatologie
                    ABD"
			/>
			<div className="border-b border-grey-light my-8" />
			<TeamTeaser
				image={findImage('f_krokowski', data.teaserImages.edges)}
				title="Frau Jutta Krokowski"
				description={
					<div>
						2008 Zusatzqualifikation zur kaufmännischen Assistentin
						im Gesundheitswesen, QM-Fachkraft <br />
						2009 Fortbildung zur Berechtigung der Aufbereitung von
						Medizinprodukten (gemäß §4 Abs. 3 MP BetriebV)
						<br />
						2017 zertifizierte Praxismanagerin
						<br />
						September 2019: Datenschutzbeauftragte
					</div>
				}
				meta="Seit 1995 in der Praxis von Dr. med. Michael H.
				Krokowski"
			/>
			<div className="border-b border-grey-light my-8" />
			<TeamTeaser
				image={findImage('guth', data.teaserImages.edges)}
				title="Frau Michaela Guth"
				description={<div>Medizinische Fachangestellte</div>}
				meta="Seit 2006 in der Praxis von Dr. med. Michael H. Krokowski"
			/>
			<div className="border-b border-grey-light my-8" />
			<TeamTeaser
				image={findImage('schoeben', data.teaserImages.edges)}
				title="Frau Ilka Schöben"
				description="Telefonischer Patientenservice"
				meta="Seit April 2010 in unserer Praxis"
			/>
			<div className="border-b border-grey-light my-8" />
			<TeamTeaser
				image={findImage('pairan', data.teaserImages.edges)}
				title="Frau Svenja Pairan"
				description="Medizinische Fachangestellte"
				meta="Seit 1995 in unserer Praxis"
			/>
			<div className="border-b border-grey-light my-8" />
			<TeamTeaser
				image={findImage('minersjan', data.teaserImages.edges)}
				title="Frau Alina Minasjan"
				description={
					<div>
						Medizinische Fachangestellte
						<br />
						November 2020: Berechtigung zur Aufbereitung von
						Medizinprodukten
					</div>
				}
				meta="Seit August 2020 in unserer Praxis"
			/>
			<div className="border-b border-grey-light my-8" />
			<TeamTeaser
				image={findImage('roemer', data.teaserImages.edges)}
				title="Frau Tanja Römer"
				description="Telefonischer Patientenservice"
				meta="Seit August 2020 in unserer Praxis"
			/>
		</Layout>
	);
};

export const query = graphql`
	query PraxisTeamQuery {
		teaserImages: allFile(filter: { relativePath: { glob: "team/*" } }) {
			edges {
				node {
					name
					childImageSharp {
						fluid(maxWidth: 250, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;

export default PraxisTeam;
